<template>
  <div class="d-none d-sm-block col-sm-8 col-lg-9 col-xl-10 acciona-bg position-relative"
       v-if="theme==='acciona' && acciona_bg === 'style_1'">
    <div class="d-flex flex-column h-100">
      <div style="height: 15%;" class="d-flex flex-row align-items-center justify-content-center">
        <div style="font-size: 3rem;" class="fw-bolder text-primary">
          BIENVENIDA (O)
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center" style="opacity: 0.5;">
        <div>
          <img src="../../assets/w7NXZz0e_4x.jpg" style="max-height: 70vh; max-width: 100%;">
        </div>
      </div>
      <div style="height: 15%;" class="d-flex flex-column">
        <div style="font-size: 1.5rem;" class="fw-bolder text-center py-2 text-primary">
          “Las mejores mentes trabajando con el corazón.”
        </div>
      </div>
    </div>
    <div class="position-absolute bottom-0 end-0 pe-3 pb-3">
      <img src="../../assets/acciona_logo.png" style="max-height: 10vh;">
    </div>
    <div class="position-absolute bottom-0 start-0 ps-3 pb-3">
      <img src="../../assets/hrae.svg" style="max-height: 20vh;">
    </div>
  </div>
  <div class="d-none d-sm-block col-sm-8 col-lg-9 col-xl-10 position-relative acciona-img-bg"
       v-else-if="theme === 'acciona' && acciona_bg === 'style_2'" >
    <div style="height: 20%;" class="d-flex flex-row align-items-center justify-content-center">
      <div style=" color: #eee;" class="fw-bolder lh-1 text-center">
        <div style="font-size: 3rem;">
          BIENVENIDA (O)
        </div>
        <div v-if="show_tagline" style="font-size: 1.5rem;" class="mt-2">
          “Las mejores mentes trabajando con el corazón.”
        </div>
      </div>
    </div>
    <div class="position-absolute bottom-0 end-0 p-2 me-3 mb-3 rounded logo-bg">
      <img src="../../assets/acciona_logo.png" style="max-height: 10vh;">
    </div>
    <div class="position-absolute bottom-0 start-0 p-3 ms-3 mb-3 rounded logo-bg">
      <img src="../../assets/hrae.svg" style="max-height: 20vh;">
    </div>
  </div>
  <div v-else-if="theme === 'kim'" class="d-none d-sm-block col-sm-8 col-lg-9 col-xl-10 kim-login-bg">

  </div>
</template>

<script>
import {useUserStore} from "../../stores/user";
import {mapState} from "pinia";

export default {
  name: "LoginBanner",
  data() {
    return {
      userStore: useUserStore(),
      acciona_bg: null,
      show_tagline: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['theme'])
  },
  setRandomStyle() {
    const randomNumber = Math.random();
    if (randomNumber < 0.5) {
      this.acciona_bg = 'style_1';
    } else {
      this.acciona_bg = 'style_2';
    }
  },
  mounted() {
    // this.setRandomStyle();
    this.acciona_bg = 'style_2';
  }
}
</script>

<style scoped>

.acciona-bg {
  background-color: #eee;
}

.acciona-img-bg {
  background: url("../../assets/w7NXZz0e_4x.jpg") center;
  background-size: cover;
}

.kim-login-bg {
  background: url("../../assets/login_bg.jpg") center;
  background-size: cover;
}

.logo-bg {
  /*background-color: rgba(238, 238, 238, 0.5);*/
  /*background: url("../../assets/brushed-alum.png") center;*/
  /*background-size: cover;*/
}
</style>