import {DateTime} from "luxon";
import humanizeDuration from "humanize-duration";

export default {
    isNumber(event) {
        event = (event) ? event : window.event;
        let charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            event.preventDefault();
        } else {
            return true;
        }
    },
    formatText(cadena) {
        let primerCaracter = cadena.charAt(0).toUpperCase();
        let restoDeLaCadena = cadena.substring(1, cadena.length);
        return primerCaracter.concat(restoDeLaCadena);
    },
    replaceChartText(cadena, charSearch, charReplace) {
        let regex = new RegExp(charSearch, "gi");
        return cadena.replace(regex, charReplace);
    },
    formatFecha(value, format = "dd/MM/yyyy") {
        return DateTime.fromISO(value).toFormat(format);
    },
    formatTextMayus(cadena) {
        return cadena ? cadena.toUpperCase() : null;
    },
    convertMinsToHrsMins(minutes) {
        let h = Math.floor(minutes / 60);
        let m = Math.floor(minutes % 60);
        let s = Math.floor((minutes * 60) % 60);
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        return h + 'h ' + m + 'm ' + s + 's';
    },
    humanizeDuration(millis) {
        if (millis) {
            return humanizeDuration(millis, {
                delimiter: "",
                spacer: "",
                round: true,
                language: "shortEn",
                languages: {
                    shortEn: {
                        y: () => "años",
                        mo: () => "meses",
                        w: () => "sem",
                        d: () => "d",
                        h: () => "h",
                        m: () => "m",
                        s: () => "s",
                        ms: () => "ms",
                    },
                },
            });
        }

        return "";
    }
}
