<template>
  <div id="app">
    <div class="vw-100 vh-100 d-flex flex-md-row flex-column">
      <main-menu v-if="is_logged_in"></main-menu>
      <div class="flex-grow-1 bg-light" style="max-height: 100%; overflow-y: auto">
        <RouterView/>
      </div>
      <main-notifications v-if="is_logged_in"
                          class="bg-secondary p-2">
      </main-notifications>
      <flash-alert></flash-alert>
    </div>
  </div>
</template>

<script>

import {mapState} from "pinia";
import {useUserStore} from './stores/user'
import MainMenu from "./components/home/MainMenu.vue";
import MainNotifications from "./components/home/MainNotifications.vue";

const theme = import.meta.env.VITE_APP_THEME;

console.log('Loading theme ' + theme);
if (theme === 'acciona') {
  import("./styles/acciona.theme.scss")
} else if (theme === 'kim') {
  import("./styles/kim.theme.scss");
}

export default {
  name: 'App',
  components: {
    MainMenu,
    MainNotifications,
  },
  computed: {
    ...mapState(useUserStore, ['is_logged_in'])
  },
  mounted() {
    // access the pinia state and set the theme
    useUserStore().$state.theme = theme;

    switch (theme) {
      case 'acciona':
        document.title = 'K-NOW V2'
        break;
      case 'kim':
        document.title = 'KIM'
        break;
      default:
        document.title = 'KIM'
        break;
    }
  },
  watch: {
    // $route(to, from) {
    //   //always clear the pusher channels when we change route
    //   for (let channel of this.$pusher.allChannels()) {
    //     channel.unsubscribe();
    //     channel.unbind_all();
    //   }
    // }
  },
}
</script>
