import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "../views/Login.vue";
import axios from "axios";
import {useUserStore} from "../stores/user";
import RolUsuario from "../code/RolUsuario";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('../views/dashboard/Dashboard.vue'),
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('../views/HomeView.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/recuperarContrasena',
      name: 'RecuperarContrasena',
      component: () => import('../views/RecuperarContrasena.vue'),
      meta: {
        guest: true
      }
    },
    {
      path: '/restablecerContrasena',
      name: 'RestablecerContrasena',
      component: () => import('../views/RestablecerContrasena.vue'),
      meta: {
        guest: true
      }
    },
    {
      path: '/mi_cuenta',
      name: 'MiCuenta',
      component: () => import('../views/cuenta/Index.vue'),
    },
    {
      path: '/usuarios',
      name: 'ListaUsuarios',
      component: () => import('../views/usuarios/Lista.vue')
    },
    {
      path: '/usuarios/create',
      name: 'CrearUsuario',
      component: () => import('../views/usuarios/CrearEditar.vue')
    },
    {
      path: '/usuarios/edit/:user_id',
      name: 'EditarUsuario',
      props: true,
      component: () => import('../views/usuarios/CrearEditar.vue')
    },
    {
      path: '/empresas',
      name: 'ListaEmpresas',
      component: () => import('../views/empresas/Lista.vue')
    },
    {
      path: '/empresas/create',
      name: 'CrearEmpresas',
      component: () => import('../views/empresas/CrearEditar.vue')
    },
    {
      path: '/empresas/edit/:empresa_id',
      name: 'EditarEmpresas',
      props: true,
      component: () => import('../views/empresas/CrearEditar.vue')
    },
    {
      path: '/ubicaciones',
      name: 'ListaUbicaciones',
      component: () => import('../views/ubicaciones/Lista.vue')
    },
    {
      path: '/ubicaciones/create',
      name: 'CrearUbicaciones',
      component: () => import('../views/ubicaciones/CrearEditar.vue')
    },
    {
      path: '/ubicaciones/edit/:ubicacion_id',
      name: 'EditarUbicaciones',
      props: true,
      component: () => import('../views/ubicaciones/CrearEditar.vue')
    },
    {
      path: '/naturalezas',
      name: 'ListaNaturalezas',
      component: () => import('../views/naturalezas/Lista.vue')
    },
    {
      path: '/naturalezas/create',
      name: 'CrearNaturalezas',
      component: () => import('../views/naturalezas/CrearEditar.vue')
    },
    {
      path: '/naturalezas/edit/:naturaleza_id',
      name: 'EditarNaturalezas',
      props: true,
      component: () => import('../views/naturalezas/CrearEditar.vue')
    },
    {
      path: '/servicios',
      name: 'ListaServicios',
      component: () => import('../views/servicios/Lista.vue')
    },
    {
      path: '/servicios/create',
      name: 'CrearServicios',
      component: () => import('../views/servicios/CrearEditar.vue')
    },
    {
      path: '/servicios/edit/:servicio_id',
      name: 'EditarServicios',
      props: true,
      component: () => import('../views/servicios/CrearEditar.vue')
    },
    {
      path: '/tipos_solicitud',
      name: 'ListaTiposSolicitudes',
      component: () => import('../views/tipos_solicitud/Lista.vue')
    },
    {
      path: '/tipos_solicitud/create',
      name: 'CrearTiposSolicitudes',
      component: () => import('../views/tipos_solicitud/CrearEditar.vue')
    },
    {
      path: '/tipos_solicitud/edit/:tipo_solicitud_id',
      name: 'EditarTiposSolicitudes',
      props: true,
      component: () => import('../views/tipos_solicitud/CrearEditar.vue')
    },
    {
      path: '/clasificaciones',
      name: 'ListaClasificaciones',
      component: () => import('../views/clasificaciones/Lista.vue')
    },
    {
      path: '/clasificaciones/create',
      name: 'CrearClasificaciones',
      component: () => import('../views/clasificaciones/CrearEditar.vue')
    },
    {
      path: '/clasificaciones/edit/:clasificacion_id',
      name: 'EditarClasificaciones',
      props: true,
      component: () => import('../views/clasificaciones/CrearEditar.vue')
    },
    {
      path: '/solicitudes',
      name: 'ListaSolicitudes',
      component: () => import('../views/solicitudes/Lista.vue')
    },
    {
      path: '/solicitudes/create',
      name: 'CrearSolicitudes',
      component: () => import('../views/solicitudes/CrearEditar.vue')
    },
    {
      path: '/solicitudes/view/:solicitud_id',
      name: 'VerSolicitudes',
      props: true,
      component: () => import('../views/solicitudes/Show.vue')
    },
    {
      path: '/mis_solicitudes',
      name: 'ListaMisSolicitudes',
      component: () => import('../views/solicitudes/ListaMisSolicitudes.vue')
    },
    {
      path: '/solicitudes_asignadas',
      name: 'ListaSolicitudesAsignadas',
      component: () => import('../views/solicitudes/ListaSolicitudesAsignadas.vue')
    },
    {
      path: '/encuestas',
      name: 'ListaEncuestas',
      component: () => import('../views/encuestas/Lista.vue')
    },
    {
      path: '/encuestas/create',
      name: 'CrearEncuestas',
      component: () => import('../views/encuestas/CrearEditar.vue')
    },
    {
      path: '/encuestas/edit/:encuesta_id',
      name: 'EditarEncuestas',
      props: true,
      component: () => import('../views/encuestas/CrearEditar.vue')
    },
    {
      path: '/solicitudes_responsables',
      name: 'ListaSolicitudesResponsables',
      component: () => import('../views/solicitudes/ListaResponsableServicio.vue')
    },
    {
      path: '/categorias_sla',
      name: 'ListaCategoriaSLA',
      component: () => import('../views/categorias_sla/Lista.vue')
    },
    {
      path: '/categorias_sla/create',
      name: 'CrearCategoriaSLA',
      component: () => import('../views/categorias_sla/CrearEditar.vue')
    },
    {
      path: '/categorias_sla/edit/:categoria_id',
      name: 'EditarCategoriaSLA',
      props: true,
      component: () => import('../views/categorias_sla/CrearEditar.vue')
    },
    {
      path: '/configuraciones',
      name: 'Configuraciones',
      component: () => import('../views/configuraciones/Index.vue'),
    },
    {
      path: '/penalizaciones',
      name: 'ListaPenalizaciones',
      component: () => import('../views/solicitudes/ListaPenalizaciones.vue')
    },
    {
      path: '/servicios_penalizacion',
      name: 'ListaServiciosPenalizacion',
      component: () => import('../views/servicios_penalizacion/Lista.vue')
    },
    {
      path: '/servicios_penalizacion/create',
      name: 'CrearServicioPenalizacion',
      component: () => import('../views/servicios_penalizacion/CrearEditar.vue')
    },
    {
      path: '/servicios_penalizacion/edit/:servicio_penalizacion_id',
      name: 'EditarServicioPenalizacion',
      props: true,
      component: () => import('../views/servicios_penalizacion/CrearEditar.vue')
    },
    {
      path: '/reportes_metabase/:reporte?',
      name: 'MetaBaseReportes',
      props: true,
      component: () => import('../views/reportes_metabase/ContenedorMetabase.vue')
    },
    {
      path: '/reportes_mesa_control',
      name: 'ReporteMesaControl',
      props: true,
      component: () => import('../views/reportes/MesaControl.vue')
    },
    {
      path: '/actividades',
      name: 'ListaActividades',
      component: () => import('../views/actividades/Lista.vue')
    },
    {
      path: '/mis_actividades',
      name: 'ListaMisActividades',
      component: () => import('../views/actividades/ListaMisActividades.vue')
    },
    {
      path: '/responsable_actividades',
      name: 'ListaResponsableActividades',
      component: () => import('../views/actividades/ListaResponsableActividades.vue')
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  const store = useUserStore();

  async function tryLogin() {
    try {
      let res = await axios.get('/api/user');
      store.data = res?.data?.data?.user;
      store.is_logged_in = true;
      store.permisos = res?.data?.data?.permisos;
      store.configuraciones = res?.data?.data?.configuraciones;
      return true;
    } catch (err) {
      return false;
    }
  }

  if (to.matched.some(record => record.meta.guest)) {
    if (store.is_logged_in && store.data != null) {
      next({name: 'Home'})
    } else {
      if (await tryLogin()) {
        next({name: 'Home'});
      } else {
        next();
      }
    }
  } else {
    if (store.is_logged_in && store.data != null) {
      next();
    } else {
      if (await tryLogin()) {
        if (to.fullPath === '/') {
          if (store.data.roles.find(element => element === RolUsuario.SUPER_ADMIN || element === RolUsuario.ADMIN)) {
            next({name: 'Dashboard'});
          } else if (store.data.roles.find(element => element === RolUsuario.MESA_CONTROL)) {
            next({name: 'ListaSolicitudes'});
          } else if (store.data.roles.find(element => element === RolUsuario.CLIENTE)) {
            next({name: 'ListaMisSolicitudes'});
          } else if (store.data.roles.find(element => element === RolUsuario.AGENTE)) {
            next({name: 'ListaSolicitudesAsignadas'});
          } else if (store.data.roles.find(element => element === RolUsuario.RESPONSABLE_SERVICIO)) {
            next({name: 'ListaSolicitudesResponsables'});
          } else if (store.data.roles.find(element => element === RolUsuario.SIN_PERMISOS)) {
            next({name: 'Home'});
          }
        } else {
          next();
        }
      } else {
        next({
          path: '/login',
          params: {nextUrl: to.fullPath}
        });
      }
    }
  }
});

export default router
