<template>
  <div class="position-absolute top-0 start-0 text-light px-1">
    {{ version }}
  </div>
  <div class="d-flex flex-column pt-3 h-100">
    <MainLogo/>

    <div class="flex-grow-1 my-3 text-center overflow-auto">
      <router-link to="/solicitudes/create" v-if="$permisos.can('solicitudes.crear')"
                   class="btn main-menu-button btn-sm d-block w-100 mb-1">
        Nueva solicitud
      </router-link>

      <hr>
      <router-link to="/" v-if="$permisos.can('dashboard.menu')"
                   class="btn main-menu-button btn-sm d-block w-100 mb-1">
        Dashboard
      </router-link>
      <router-link :to="{'name': 'MetaBaseReportes', 'params': {'reporte': 'reportes.metabase.dashboard'}}"
                   v-if="$permisos.can('reportes_1.menu')"
                   class="btn main-menu-button btn-sm d-block w-100 mb-1">
        Reportes 1
      </router-link>
      <router-link to="/reportes_mesa_control?reset"
                   v-if="$permisos.can('reportes.mesa_control')"
                   class="btn main-menu-button btn-sm d-block w-100 mb-1">
        Reportes mesa de control
      </router-link>

      <hr>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/mis_solicitudes?reset"
                   v-if="$permisos.can('solicitudes.menu.mis_solicitudes')">
        Mis solicitudes
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/solicitudes_asignadas?reset"
                   v-if="$permisos.can('solicitudes.menu.solicitudes_asignadas')">
        Mis asignaciones
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/solicitudes?reset"
                   v-if="$permisos.can('solicitudes.menu')">
        Solicitudes
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/solicitudes_responsables?reset"
                   v-if="$permisos.can('solicitudes.menu.responsables_servicio')">
        Mis responsabilidades
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/penalizaciones?reset"
                   v-if="$permisos.can('solicitudes.menu.penalizaciones') && show_porcentaje_ponderacion === 'true'">
        Penalizaciones
      </router-link>

      <template v-if="show_config_actividades">
        <hr>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/actividades"
                     v-if="$permisos.can('actividades.menu.todas')">
          Actividades
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/mis_actividades"
                     v-if="$permisos.can('actividades.menu.mis_actividades')">
          Mis actividades
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/responsable_actividades"
                     v-if="$permisos.can('actividades.menu.responsables')">
          Actividades de responsable
        </router-link>
      </template>

      <hr>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/usuarios"
                   v-if="$permisos.can('usuarios.menu')">
        Usuarios
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/empresas"
                   v-if="$permisos.can('empresas.menu')">
        Empresas
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/ubicaciones"
                   v-if="$permisos.can('ubicaciones.menu')">
        Ubicaciones
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/naturalezas"
                   v-if="$permisos.can('naturalezas.menu')">
        Naturalezas
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/servicios"
                   v-if="$permisos.can('servicios.menu')">
        Servicios
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/tipos_solicitud"
                   v-if="$permisos.can('tipos_solicitud.menu')">
        Tipos solicitudes
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/clasificaciones"
                   v-if="$permisos.can('clasificaciones.menu')">
        Clasificaciones
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/categorias_sla"
                   v-if="$permisos.can('categorias_sla.menu')">
        Categorías SLA
      </router-link>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/servicios_penalizacion"
                   v-if="$permisos.can('servicios_penalizacion.menu') && show_porcentaje_ponderacion === 'true'">
        Servicios de penalización
      </router-link>
      <hr>
      <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/encuestas"
                   v-if="$permisos.can('encuestas.menu')">
        Encuestas
      </router-link>
    </div>
    <div class="my-2">
      <button v-if="showHideButton" class="btn btn-outline-light btn-sm float-end mb-2" @click="$emit('hide')">
        <i class="fa fa-less-than"></i>
      </button>
      <router-link class="btn btn-sm main-menu-button w-100 mb-2" to="/configuraciones"
                   v-if="$permisos.can('configuraciones.menu')">
        <i class="fa fa-cog me-1"></i>
        <span>Configuraciones</span>
      </router-link>
      <router-link to="/mi_cuenta" class="btn btn-sm main-menu-button w-100 mb-2"
                   v-tooltip.top="'Mi cuenta'">
        <i class="fa fa-user-circle me-1"></i>
        <span>
              {{ data.full_name }}
            </span>
      </router-link>
      <button @click="$emit('logout')" class="btn btn-sm main-menu-button w-100">
        Cerrar sesión
      </button>
    </div>
    <div class="dev-by px-2 pt-1">
      <img src="../../assets/dev_by_white.png" class="w-100">
    </div>
  </div>
</template>
<script>
import {mapState} from "pinia";
import {useUserStore} from "@/stores/user";
import MainLogo from "@/components/home/MainLogo.vue";
import {VERSION} from "@/code/app";

export default {
  name: 'FullSizeMenu',
  components: {MainLogo},
  props: {
    showHideButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      version: VERSION,
      show_porcentaje_ponderacion: import.meta.env.VITE_PORCENTAJE_PONDERACION,
      show_config_actividades: false
    }
  },
  computed: {
    ...mapState(useUserStore, ['data'])
  },
  mounted() {
    if (import.meta.env.VITE_SHOW_ACTIVIDADES !== undefined && import.meta.env.VITE_SHOW_ACTIVIDADES === 'true') {
      this.show_config_actividades = true;
    }
  },
}
</script>