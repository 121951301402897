<template>
  <div>
    <div :class="small ? [] : ['mt-2', 'mb-3', 'p-3', 'pb-0']" v-if="theme === 'acciona'">
      <img src="../../assets/know2.png" class="w-100">
    </div>
    <div :class="small ? [] : ['mt-2', 'mb-3', 'p-3']" v-else>
      <img src="../../assets/logo_light.svg" class="w-100">
    </div>
  </div>
</template>
<script>
import {mapState} from "pinia";
import {useUserStore} from "../../stores/user";

export default {
  name: 'MainLogo',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useUserStore, ['theme'])
  },
}
</script>
