const SUPER_ADMIN = 'super_admin';
const ADMIN = 'admin';
const MESA_CONTROL = 'mesa_control';
const CLIENTE = 'cliente';
const AGENTE = 'agente';
const RESPONSABLE_SERVICIO = 'responsable_servicio';
const EXTERNO = 'externo';
const SUP_SERVICIOS_GENERALES = 'sup_servicios_generales';
const SIN_PERMISOS = 'sin_permisos';

export default {
  getRoles() {
    return [
      {"title": "Super administrador", "value": SUPER_ADMIN},
      {"title": "Administrador general", "value": ADMIN},
      {"title": "Mesa de control", "value": MESA_CONTROL},
      {"title": "Cliente", "value": CLIENTE},
      {"title": "Agente", "value": AGENTE},
      {"title": "Responsable de servicio", "value": RESPONSABLE_SERVICIO},
      {"title": "Externo", "value": EXTERNO},
      {"title": "Supervisor Servicios Generales", "value": SUP_SERVICIOS_GENERALES},
      {"title": "Sin permisos", "value": SIN_PERMISOS}
    ];
  },
  SUPER_ADMIN,
  ADMIN,
  MESA_CONTROL,
  CLIENTE,
  AGENTE,
  RESPONSABLE_SERVICIO,
  EXTERNO,
  SUP_SERVICIOS_GENERALES,
  SIN_PERMISOS
}
