import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    is_logged_in: false,
    data: null,
    permisos: null,
    configuraciones: null,
    theme: null
  })
})
