import {useUserStore} from "../stores/user";

const configuraciones = {
  get(key) {
    const store = useUserStore();
    return store.configuraciones.find(x => x.id === key);
  },
  getAll() {
    const store = useUserStore();
    return store.configuraciones;
  }
};

export default configuraciones;

// {{$configuraciones.get(TiposConfiguracion.ENTREGA, KeysConfiguracion.TIEMPO_ANTICIPACION).value}}
