const ASIGNADA_AGENTE = 'asignada_agente';
const MENSAJE_NUEVO = 'mensaje_nuevo';
const TIEMPO_EXTRA ='tiempo_extra';
const SOLICITUD_VALIDADA = 'solicitud_validada';

const SOLICITUD_CREADA = 'solicitud_creada';
const ASIGNADA_SERVICIO = 'asignada_servicio';
const EN_ATENCION = 'en_atencion';
const CERRADA = 'cerrada';
const RECORDATORIO_VALIDACION = 'recordatorio_validacion';
const RESPONDER_ENCUESTA = 'responder_encuesta';
const SLA_NO_SUPERADO = 'sla_no_superado';
const SLA_SUPERADO = 'sla_superado';
const SOLICITUD_VALIDADA_AUTO = 'solicitud_validada_auto';
const TIEMPO_EXTRA_STATUS ='tiempo_extra_status';

export default {
    getAll() {
        return [
            {"title": 'Asignada al agente', "value": ASIGNADA_AGENTE},
            {"title": 'Mensaje nuevo', "value": MENSAJE_NUEVO},
            {"title": 'Ampliación de tiempo', "value": TIEMPO_EXTRA},
            {"title": 'Solicitud validada', "value": SOLICITUD_VALIDADA},
            {"title": 'Solicitud creada', "value": SOLICITUD_CREADA},
            {"title": 'Asignada al servicio', "value": ASIGNADA_SERVICIO},
            {"title": 'En atención', "value": EN_ATENCION},
            {"title": 'Solicitud cerrada', "value": CERRADA},
            {"title": 'Recordatorio de validación', "value": RECORDATORIO_VALIDACION},
            {"title": 'Responder la encuesta', "value": RESPONDER_ENCUESTA},
            {"title": 'Por superar SLA', "value": SLA_NO_SUPERADO},
            {"title": 'SLA superado', "value": SLA_SUPERADO},
            {"title": 'Solicitud validada automáticamente', "value": SOLICITUD_VALIDADA_AUTO},
            {"title": 'Respuesta de ampliación de tiempo', "value": TIEMPO_EXTRA_STATUS},
        ];
    },
    ASIGNADA_AGENTE,
    MENSAJE_NUEVO,
    TIEMPO_EXTRA,
    SOLICITUD_VALIDADA,
    SOLICITUD_CREADA,
    ASIGNADA_SERVICIO,
    EN_ATENCION,
    CERRADA,
    RECORDATORIO_VALIDACION,
    RESPONDER_ENCUESTA,
    SLA_NO_SUPERADO,
    SLA_SUPERADO,
    SOLICITUD_VALIDADA_AUTO,
    TIEMPO_EXTRA_STATUS,
}