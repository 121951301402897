<template>
  <Dialog :visible="show" :modal="true" :draggable="false" :showHeader="false" class="dialog-no-footer">
    <div class="d-flex align-items-center justify-content-center">
      <ProgressSpinner style="width: 50px; height: 50px;" class="me-3"/>
      Cargando...
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import _ from 'lodash';

export default {
  name: "DialogoCargando",
  components: {
    Dialog, ProgressSpinner
  },
  props: {
    cargando: {
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  created() {
    this.$watch('cargando', _.debounce(this.setShow, 500))
  },
  methods: {
    setShow(val) {
      this.show = val;
    }
  }
}
</script>
