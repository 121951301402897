<template>
  <div>
    <slot></slot>
    <h5>
      <strong v-if="errors[name]!=undefined" class="text-light badge bg-danger text-wrap">
        {{ getError() }}
      </strong>
    </h5>
  </div>
</template>

<script>
export default {
  props: ['errors', 'name'],
  methods: {
    getError: function () {
      return this.errors[this.name][0];
    }
  }
}
</script>
