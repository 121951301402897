<template>
    <label class="mb-0">{{ localText.length }}/{{ length }}</label>
</template>

<script>
    export default {
        name: "LabelTextCounter",
        props: {
            text: {
                type: String,
                dafault: ''
            },
            length: {
                type: Number,
                default: 0
            }
        },
        data: function () {
            return {
                localText: ''
            }
        },
        methods: {
            setValue: function () {
                if(this.text == undefined || this.text == null){
                    this.localText = '';
                } else {
                    this.localText = this.text;
                }
            }
        },
        created: function () {
            this.setValue();
        },
        watch: {
            text: function(){
                this.setValue();
            }
        }
    }
</script>

<style scoped>

</style>
