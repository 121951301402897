<template>
  <div class="d-none d-md-block" :class="{'big': big, 'small': !big}">
    <div v-if="big" class="d-flex flex-column h-100">
      <div class="mb-2">
        <div class="text-light text-center h3 m-0 flex-grow-1">
          Notificaciones
        </div>
      </div>

      <div class="text-center text-light flex-grow-1 my-3" v-if="notificaciones.length === 0">
        <div class="card border border-light bg-secondary text-light mb-1">
          <div class="card-body p-1">
            <div class="row">
              <div class="col-sm-12">
                <span><b>No hay notificaciones para mostrar</b></span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;" v-else>
        <div class="card border border-light bg-secondary text-light mb-2"
             v-for="notificacion in notificaciones">
          <div class="card-body p-1 ">
            <div class="row">
              <div class="col-sm-12">
                <span class="badge w-100 bg-black">
                    <b>{{ notificacion.name_tipo }}</b>
                </span>
              </div>
              <div class="col-sm-10">
                <div :class="!notificacion.more ? 'collapse-notificacion' : ''">
                  <div>
                    {{ notificacion.text }}
                    <button class="btn btn-outline-light btn-sm d-block w-100 m-2"
                            @click="detalleSolicitud(notificacion)"
                            v-if="notificacion.more && notificacion.notification_type === 'App\\Solicitud'">
                      Solicitud
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <button class="btn btn-success float-end btn-sm" @click="marcarVisto(notificacion)">
                  <i v-if="!saving" class="fa fa-check"></i>
                  <i v-else class="fa fa-spinner fa-spin"></i>
                </button>
              </div>
              <div class="col-sm-12 cursor-pointer">
                <div class="col-sm-12 d-flex flex-row">
                  <div class="flex-grow-1">
                    <span class="small"> {{ utils.formatFecha(notificacion.created_at, 'dd/MM/yyyy HH:mm:ss ') }}</span>
                  </div>
                  <div>
                    <span class="text-light">
                        <span v-if="!notificacion.more" @click="showMore(notificacion)">
                            <b><i><u>Leer más</u></i></b>
                        </span>
                        <span v-else @click="showMore(notificacion)">
                            <b><i><u>Leer menos</u></i></b>
                        </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-light d-flex flex-row align-items-center">
        <div class="">
          <button @click="collapseNotificaciones" class="btn btn-sm btn-outline-light me-1 px-2">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-light me-2" v-if="notificaciones_ids.length > 0"
                  @click="marcarAllVisto" v-tooltip="'Marcar todas como vistas'">
            <i class="fa fa-eye"></i> Todas
          </button>
        </div>
        <div class="flex-grow-1 float-end text-end">
          {{ datasCount + (datasCount === 0 || datasCount > 1 ? ' Resultados' : ' Resultado') }}
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column h-100">
      <div class="text-light h3 m-0 text-center">
        <i class="fa fa-bell"></i>
        <span class="spinner-grow spinner-grow-sm position-absolute translate-middle bg-warning float-end" role="status"
              aria-hidden="true" v-if="newNotificacion"></span>
      </div>
      <div class="flex-grow-1">
        <div class="badge rounded-pill text-dark mt-2 py-1 px-1 w-100"
             :class="{'bg-warning': notificaciones.length > 0, 'bg-success': notificaciones.length === 0}"
             style="font-size: 1rem;">
          <span v-if="notificaciones.length > 99">99+</span>
          <span v-else>{{ notificaciones.length }}</span>
        </div>
      </div>
      <button @click="collapseNotificaciones" class="btn btn-sm btn-outline-light me-1 px-2">
        <i class="fa fa-chevron-left"></i>
      </button>
    </div>
  </div>
</template>
<script>
import Paginador from "../../components/utils/Paginador.vue";
import Utils from "../../code/Utils";
import TipoNotificacion from "../../code/TipoNotificacion";
import {mapState} from "pinia/dist/pinia";
import {useUserStore} from "../../stores/user";
import RolUsuario from "../../code/RolUsuario";
import _ from 'lodash';

export default {
  name: 'Notificaciones',
  components: {
    Paginador,
  },
  data() {
    return {
      errors: [],
      notificaciones: {},
      filtros: {
        paginate: true,
        visto: 0,
        user_id: null,
      },
      notificacion: {
        id: null,
        more: false,
        notification_id: null,
        notification_type: null,
        text: null,
        tipo: null,
      },
      big: true,
      saving: false,
      timeInterval: null,
      datasCount: 0,
      utils: Utils,
      TipoNotificacion,
      user: [],
      newNotificacion: false,
      notificaciones_ids: [],
    };
  },
  computed: {
    ...mapState(useUserStore, ['data'])
  },
  mounted() {
    this.big = localStorage.getItem('collapse.notificaciones') === 'true';
    this.configPusher();
    this.getNotificaciones();
  },
  methods: {
    configPusher() {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.register('sw.js');
      }

      Notification.requestPermission();

      this.channel = this.$pusher.subscribe('kim.notificaciones.' + this.data.id);
      this.channel.bind("App\\Events\\NotificacionCreada", (data) => {
        console.debug('NotificacionCreada', data)

        if (Notification.permission === 'granted') {
          let tipo = TipoNotificacion.getAll().filter(t => t.value === data.notificacion.tipo);

          if (navigator.serviceWorker) {
            navigator.serviceWorker.ready.then(registration => {
              registration.showNotification(tipo[0]['title'], {
                body: data.notificacion.text,
                icon: '/public/favicon-edi.png'
              });
            });
          }
        }

        this.notificaciones.unshift(data.notificacion);
        this.notificaciones_ids.unshift(data.notificacion.id);
        this.newNotificacion = true;
      });
    },
    getNotificaciones() {
      this.saving = true;
      this.filtros.user_id = this.data.id;
      axios.get('api/notificaciones', {
        params: {
          campos: this.filtros,
        }
      })
          .then(res => {
            if (res.data.status === 'ok') {
              this.notificaciones = res.data.data.items.data ?? {};
              this.datasCount = res.data.data.total;
              this.notificaciones_ids = _.map(this.notificaciones, 'id');
            }
            this.saving = false;
          });
    },
    showMore(notificacion) {
      this.notificacion = notificacion;
      this.notificacion.more = !this.notificacion.more;
    },
    marcarVisto(notificacion) {
      this.saving = true;
      this.notificacion = notificacion;
      this.notificacion.visto = true;

      axios.put('api/notificaciones/' + this.notificacion.id, this.notificacion)
          .then(res => {
            if (res.data.status === 'ok') {
              this.getNotificaciones();
              this.$emitter.emit('messages.show_success', res.data.data.message);
            } else {
              this.$emitter.emit('messages.show_error', res.data.data.message);
            }
            this.saving = false;
          }).catch(error => {
        this.saving = false;
        this.errors = error?.response?.data?.errors ?? [];
      });
    },
    detalleSolicitud(data) {
      this.$router.push("/solicitudes/view/" + data.notification_id);
    },
    collapseNotificaciones() {
      this.big = !this.big;
      this.newNotificacion = false;
      localStorage.setItem('collapse.notificaciones', this.big);
    },
    marcarAllVisto() {
      this.saving = true;

      axios.post('api/notificaciones/all_vistas')
          .then(res => {
            if (res.data.status === 'ok') {
              this.getNotificaciones();
              this.$emitter.emit('messages.show_success', res.data.data.message);
            } else {
              this.$emitter.emit('messages.show_error', res.data.data.message);
            }
            this.saving = false;
          }).catch(error => {
        this.saving = false;
        this.errors = error?.response?.data?.errors ?? [];
      });
    },
  }
}
</script>
<style scoped="">
.collapse-notificacion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.big {
  min-width: 250px;
  max-width: 250px;
}
</style>