import {useUserStore} from "../stores/user";

const permisos = {
    can(permiso) {
        const store = useUserStore();
        return store.permisos.includes(permiso);
    }
};

export default permisos;
