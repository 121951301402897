<template>
  <div>
    <div class="h-100 d-none d-md-block">
      <div style="min-width: 200px; max-width: 200px;" class="bg-primary h-100 p-2" v-show="show_menu">
        <FullSizeMenu @logout="logout" @hide="show_menu = false"/>
      </div>
      <div style="min-width: 55px; max-width: 55px;" class="bg-primary h-100 p-2" v-show="!show_menu">
        <div class="d-flex flex-column h-100">
          <div>
            <MainLogo :small="true"/>
          </div>
          <div class="flex-grow-1 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-light btn-sm float-end mb-2" style="height: fit-content !important;"
                    @click="show_menu = true">
              <i class="fa fa-greater-than"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block d-md-none">
      <div class="bg-primary" style="height: 3.5rem;">
        <div class="d-flex flex-row align-items-center h-100 p-2 gap-2">
          <button class="btn btn-outline-light" @click="show_sidebar = true">
            <i class="fa fa-bars"></i>
          </button>
          <div v-if="theme === 'acciona'">
            <img src="../../assets/know2.png" style="max-height: 1.5rem;">
          </div>
          <div v-else>
            <img src="../../assets/logo_light.svg" style="max-height: 2.5rem;">
          </div>
        </div>
      </div>
      <Sidebar v-model:visible="show_sidebar" :show-close-icon="false" style="width: 70vw;"
               :pt="{root: { class: 'bg-primary' }, content: { class: 'p-0' }, header: {class: 'p-0'}}">
        <div class="bg-primary h-100 p-3">
          <FullSizeMenu @logout="logout" @hide="show_menu = false" :show-hide-button="false"/>
        </div>
      </Sidebar>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {useUserStore} from "../../stores/user";
import {mapState} from "pinia";
import {VERSION} from '../../code/app';
import MainLogo from "./MainLogo.vue";
import Sidebar from "primevue/sidebar";
import FullSizeMenu from "@/components/home/FullSizeMenu.vue";
// import RolUsuario from "@/code/RolUsuario";

export default {
  name: 'Menu',
  components: {
    MainLogo,
    FullSizeMenu,
    Sidebar
  },
  data() {
    return {
      version: VERSION,
      show_menu: true,
      show_sidebar: false,
      show_porcentaje_ponderacion: import.meta.env.VITE_PORCENTAJE_PONDERACION,
      show_config_actividades: false
    }
  },
  methods: {
    logout() {
      const store = useUserStore();
      axios.post('/logout').then(() => {
        store.data = null;
        store.is_logged_in = null;
        this.$router.replace('/login');
      });
    }
  },
  mounted() {
    if (import.meta.env.VITE_SHOW_ACTIVIDADES !== undefined && import.meta.env.VITE_SHOW_ACTIVIDADES === 'true') {
      this.show_config_actividades = true;
    }
  },
  computed: {
    ...mapState(useUserStore, ['data']),
    ...mapState(useUserStore, ['theme'])
  }
}
</script>
